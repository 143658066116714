
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$isolve-website-frontend-primary: mat.define-palette(mat.$indigo-palette);
$isolve-website-frontend-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$isolve-website-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$isolve-website-frontend-theme: mat.define-light-theme((
  color: (
    primary: $isolve-website-frontend-primary,
    accent: $isolve-website-frontend-accent,
    warn: $isolve-website-frontend-warn,
  )
));

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$isolve-website-frontend-primary: mat.define-palette(mat.$indigo-palette);
$isolve-website-frontend-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$isolve-website-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$isolve-website-frontend-theme: mat.define-light-theme((
  color: (
    primary: $isolve-website-frontend-primary,
    accent: $isolve-website-frontend-accent,
    warn: $isolve-website-frontend-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($isolve-website-frontend-theme);

/* You can add global styles to this file, and also import other style files */

.pointer {
  cursor: pointer;
}

:root {
  --primary: #000066;
  --primary-conome: #0065fe;
  --secondary: #00FFFF;
  --secondary-light: #00FFFF12;
  --disabled: #6D6E71;
  --disabled-light-1: #f3f3f3;
  --tertiary: #006;
  --disabled-2: #747474;
  --disabled-3: #adadad;
}

.bg-primary {
  background-color: var(--primary)
}

.bg-secondary {
  background-color: var(--secondary) !important;
}

.primary-title {
  color: var(--primary);
  font-family: "Overpass", Sans-serif;
  font-size: 48px;
  font-weight: 800;
  line-height: 58px;
}

.secondary-title {
  color: var(--secondary);
  font-family: "Overpass", Sans-serif;
  font-size: 48px;
  font-weight: 800;
  line-height: 58px;
}

.secondary-hover {
  background-color: white;
  color: var(--primary);
  font-size: 16px;
  font-weight: 700;
  border-width: 2px;
  border: 0px;
  padding: 20px 25px;
  border-radius: 5px;

  &:hover {
    color: white;
    background-color: var(--primary);
  }
}

.disabled-text {
  color: var(--disabled);
}
.disabled-2-text{
  color: var(--disabled-2)
}

.btn-primary-seconday {
  font-family: "Overpass", Sans-serif;
  font-size: 19px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 2px;
  padding: 20px 40px;
  color: white;
  background: linear-gradient(to right, var(--primary) 50%, var(--secondary) 50%);
  background-size: 200% 100%;
  transition: background-position 0.3s ease;

  &:hover {
    background-position: -100% 0;
    color: var(--primary);
  }
}

.default-padding{
  padding: 0px 15% !important;
}
@media(max-width: 989px){ 
.default-padding{
  padding: 0px 1% !important;
}
}


.btn-primary-outline{
  font-family: "Dosis",Sans-serif;
  font-size: 22px;
  font-weight: 700;
  margin: 0;
  border-style: solid;
  border-width: 2px;
  border-color: #006;

  background-color: white;
  border: 1px solid var(--primary);
  color: var(--primary);
  padding: 15px 100px;
  &:hover{
    color: white;
    background-color: var(--primary);;
  }
}

.primary-text{
  color: var(--primary)
}

.pos-relative {
  position: relative;
}

.bg-cover {
  height: 400px;
  background-attachment: fixed;
  padding: 150px 50px;
  background-size: cover;
}

@media (max-width: 1024px){
  h1 {
    font-size: 40px !important;
  }
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
